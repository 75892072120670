<template>
  <el-dialog
    :title="title"
    :model-value="modelValue"
    width="600px"
    draggable
    destroy-on-close
    :z-index="2010"
    @close="closed"
  >
    <div class="news-create-container">
      <el-form
        ref="ruleFormRef"
        style="flex: 2"
        :model="form"
        :rules="formRule"
        label-width="120px"
      >
        <el-form-item label="单位名称" prop="unitName" required>
          <el-input v-model.trim="form.unitName"></el-input>
        </el-form-item>

        <el-form-item label="数据类型">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">整数</el-radio>
            <el-radio :label="2">浮点数</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>

    <el-form :model="form" label-width="120px">
      <el-form-item>
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="onSubmit"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed, onMounted } from 'vue'
import { storelist } from '@/api/store.js'
import * as goodsApi from '@/api/goods.js'
import { ElMessage } from 'element-plus'
import { rules } from '../data.js'

const props = defineProps({
  modelValue: {
    type: Boolean,
    require: true
  },
  info: {
    type: Object
  }
})

const emits = defineEmits(['update:modelValue', 'updateClient'])

// 判断是否有参数传递过来
// const isInfoFlag = computed(() => {
//   return JSON.stringify(props.info) === '{}'
// })

// 标题
const title = computed(() => {
  return JSON.stringify(props.info) === '{}' ? '创建' : '修改'
})

// 数据源
const formRule = rules
const form = ref({
  unitName: '',
  type: 1
})
// 取消
const closed = () => {
  form.value = {
    unitName: '',
    type: 1
  }
  emits('update:modelValue', false)
}

// 提交事件
const submitLoading = ref(false)
const ruleFormRef = ref(null)
const onSubmit = async () => {
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (!valid) {
      console.log('error submit!', fields, Object.keys(fields)[0])
      ElMessage.error(fields[Object.keys(fields)[0]][0].message)
      return
    }

    submitLoading.value = true

    console.log('form.value :>> ', form.value)
    try {
      await goodsApi.addUnit(form.value)
      ElMessage.success('更新成功')
      emits('updateClient')
      closed()
    } finally {
      submitLoading.value = false
    }
  })
}

// 获取数据方法
const loading = ref(false)
const getDetail = async () => {
  loading.value = true
  try {
    form.value = JSON.parse(JSON.stringify(props.info))
  } catch (error) {
    console.log('error :>> ', error)
  } finally {
    loading.value = false
  }
}
watch(
  [() => props.info, () => props.modelValue],
  async ([infoVal, modelValueVal]) => {
    if (JSON.stringify(infoVal) !== '{}') getDetail()
  }
)
</script>

<style lang="scss" scoped>
.news-create-container {
  display: flex;
  ::v-deep .el-form {
    flex: 1;
  }
}
</style>
