import request from '@/utils/request'

const baseUrl = '/manager/warehouse'

/**
 * 获取列表
 */
export const goodslist = data => {
  return request({
    url: `${baseUrl}/pageVo`,
    params: data
  })
}

/**
 * 创建
 */
export const addGoods = data => {
  return request({
    url: baseUrl,
    method: 'POST',
    data
  })
}

/**
 * 更新
 */
export const updateGoods = data => {
  return request({
    url: baseUrl,
    method: 'PUT',
    data
  })
}

/**
 * 删除
 */
export const deleteGoods = id => {
  return request({
    url: `${baseUrl}/${id}`,
    method: 'DELETE'
  })
}

/**
 * 查询单位
 */
export const listUnit = () => {
  return request({
    url: `${baseUrl}/listUnit`
  })
}

/**
 * 创建单位
 */
export const addUnit = data => {
  return request({
    url: `${baseUrl}/addUnit`,
    method: 'POST',
    data
  })
}
