<template>
  <div class="user-manage-container">
    <!-- <el-card class="header">
      <search @search="searchClick" @clear="clearClick"></search>
    </el-card> -->
    <el-card>
      <div class="table-head-box">
        <el-button
          style="float: right"
          :icon="Refresh"
          size="small"
          round
          @click="getListData"
        ></el-button>
        <el-button
          v-permission="['addUnit']"
          class="button"
          type="primary"
          size="small"
          @click="handleCreate"
        >
          新增
        </el-button>
      </div>

      <el-table
        ref="multipleTable"
        :data="tableData"
        v-loading="loading"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" width="150" align="center" />
        <el-table-column
          prop="unitName"
          label="单位名称"
          min-width="250"
          align="center"
          show-overflow-tooltip
        />

        <el-table-column label="数据类型" min-width="180" align="center">
          <template #default="{ row }" align="center">
            {{ row.type ? numberType[row.type] : '' }}
          </template>
        </el-table-column>
      </el-table>

      <!-- <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[2, 5, 10, 20, 50, 100, 500, 1000]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination> -->
    </el-card>

    <create-dialog
      v-model="createDialogVisible"
      :info="editSelectClient"
      @updateClient="getListData"
    ></create-dialog>
  </div>
</template>

<script setup>
import { ref, onActivated, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as goodsApi from '@/api/goods.js'
import { ElMessageBox, ElMessage } from 'element-plus'
import { ArrowDown, Refresh } from '@element-plus/icons-vue'
// import search from './components/search.vue'
import CreateDialog from './components/create.vue'

const store = useStore()

// 数据相关
const tableData = ref([])
const multipleTable = ref(null)
const total = ref(0)
const page = ref(1)
const size = ref(10)
const searchFrom = ref({})
const loading = ref(false)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await goodsApi.listUnit({
    current: page.value,
    size: size.value,
    ...Object.fromEntries(
      Object.entries(searchFrom.value).filter(
        item => item[1] !== '' || item[1] === 0
      )
    )
  })
  tableData.value = result
  total.value = result.length
  /* tableData.value = result.items
  total.value = result.total */
  loading.value = false
}

// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

const selectedIndexs = ref([])
const handleSelectionChange = e => {
  selectedIndexs.value = e
}

// 新增点击事件
const createDialogVisible = ref(false)
const handleCreate = () => {
  createDialogVisible.value = true
}
// 修改点击事件
const editSelectClient = ref({})
const handleUpdate = row => {
  editSelectClient.value = row
  createDialogVisible.value = true
}
watch(createDialogVisible, val => {
  if (!val) editSelectClient.value = {}
})

/**************************/
// // 分页相关
// /**
//  * size 改变触发
//  */
// const handleSizeChange = currentSize => {
//   size.value = currentSize
//   getListData()
// }

// /**
//  * 页码改变触发
//  */
// const handleCurrentChange = currentPage => {
//   page.value = currentPage
//   getListData()
// }

// // 搜索
// const searchClick = e => {
//   page.value = 1
//   searchFrom.value = e
//   getListData()
// }
// // 清空
// const clearClick = () => {
//   page.value = 1
//   searchFrom.value = {}
//   getListData()
// }
/**************************/

const numberType = {
  1: '整数',
  2: '浮点数'
}
</script>

<style lang="scss" scoped>
.user-manage-container {
  .header {
    margin-bottom: 22px;
    text-align: right;
  }
  ::v-deep .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  ::v-deep .el-tag {
    margin-right: 6px;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}

.table-head-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 14px;
  .button {
    margin: 0 5px;
  }
}
</style>
